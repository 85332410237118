import React from 'react';
import Image from 'next/image';

const SportLogo = ({ width = 84, height = 84 }) => {
    return (
        <Image
            src="/icons/sport2000.svg"
            style={{ width, height }}
            loader={({ src }) => src}
            alt="Sport 2000 Logo"
            width={width}
            height={height}
            priority
            unoptimized
        />
    );
};

export default SportLogo;
