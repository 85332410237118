import React, { useState } from 'react'
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl'
import classnames from 'classnames'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import ProductImage from '../../atoms/image/ProductImage'
import PriceHelper from '../../../services/price'
import { AlgoliaHit } from './types'
import Price from '../../atoms/price'
import Link from 'next/link'
import { TransitionLink } from 'components/commercetools-ui/transition-link'

export type Props = {
  searchResult: any,
  searchHitsOpen: boolean,
  value?: string | number | null
  selectedCategoryOption: any
  filteredCategory: any
  setFilteredCategory: any
  setIsShowCategoryEmptyMsg: any
} & WrappedComponentProps

type Variant = {
  price: number,
  discountedPrice: number | null
}

const SearchHits: React.FC<Props> = ({
  searchResult,
  searchHitsOpen,
  value,
  filteredCategory,
  setFilteredCategory,
  setIsShowCategoryEmptyMsg,
  selectedCategoryOption,
}: Props) => {
  if (!searchResult) {
    return null
  }
  const { hits } = searchResult
  const { indexName } = searchResult
  const numberOfHits = searchResult?.nbHits || 0
  const closeSearch = () => {
    const customEvent = new Event('onCloseSearch')

    document.dispatchEvent(customEvent)
  }

  const filteredCategoryParams = selectedCategoryOption && filteredCategory ? `&categoryFilter=${filteredCategory}&categoryOption=${selectedCategoryOption.id}&categoryKey=${selectedCategoryOption.key}&categoryName=${selectedCategoryOption.name}` : ''
  const renderPrice = (hit) => {
    const priceVariants = PriceHelper.getPrice(hit)
    const isProductOnSale = PriceHelper.isProductOnSale(priceVariants)
    const { lowestPrice, hasMultiplePrices } = PriceHelper.findLowestAndCheckPrices(priceVariants)

    return (
      <>
        {hasMultiplePrices && (<span className={classnames({
          'text-red-600': isProductOnSale,
        })}>
          <FormattedMessage id="productTeaser.from" />
          {' '}
        </span>)}
        <Price variant={`${isProductOnSale && 'text-red-600'} text-xl font-bold`} value={lowestPrice} />
      </>
    )
  }
  // const renderPrice = (hit) => {
  //     const priceVariants = PriceHelper.getPrice(hit)
  //     const variantsPrices = PriceHelper.getVariantsPrice(priceVariants)
  //     const isProductOnSale = PriceHelper.isProductOnSale(priceVariants)
  //     const variantsSalePrices = PriceHelper.getVariantsSalePrice(priceVariants)
  //     const lowestPrice = PriceHelper.getLowestPrice(variantsPrices)
  //     const highestPrice = PriceHelper.getHighestPrice(variantsPrices)
  //     const salePricesWithoutNull = variantsSalePrices.filter((salePrice) => !!salePrice)
  //     const lowestSalePrice = PriceHelper.getLowestPrice(salePricesWithoutNull)
  //     const highestSalePrice = PriceHelper.getHighestPrice(salePricesWithoutNull)
  //
  //     if (isProductOnSale && salePricesWithoutNull.length) {
  //         if (lowestSalePrice > 0 && lowestSalePrice > lowestPrice) {
  //             return (
  //                 <>
  //                     <FormattedMessage id={'productTeaser.from'} />
  //                     {' '}
  //                     <Price variant={'text-xl font-bold'} value={lowestPrice} />
  //                 </>
  //             )
  //         }
  //
  //         return (
  //             <>
  //                 {(lowestSalePrice !== highestSalePrice)
  //                     && (
  //                         <span className={'text-red-600'}>
  //                             <FormattedMessage id={'productTeaser.from'} />
  //                             {' '}
  //                         </span>
  //                     )}
  //                 <Price variant={'text-red-600 text-xl font-bold'} value={lowestSalePrice} />
  //             </>
  //         )
  //     }
  //
  //     if (lowestPrice === highestPrice) {
  //         return <Price variant={'text-xl font-bold'} value={lowestPrice} />
  //     }
  //
  //     return (
  //         <>
  //             <FormattedMessage id={'productTeaser.from'} />
  //             {' '}
  //             <Price variant={'text-xl font-bold'} value={lowestPrice} />
  //         </>
  //     )
  // }

  const filterAttributeShoeWidth = (hit) => {
    const attributeShoeWidth = hit.attributes.att_prop_shoe_general_width

    if (!attributeShoeWidth) {
      return null
    }

    if (attributeShoeWidth.toLowerCase() === 'normal') {
      return null
    }

    return attributeShoeWidth
  }

  return (
    <>
      {hits.length > 0 && (
        <div className={classnames('search--product', {
          hidden: !searchHitsOpen,
        })}
        >
          <div className={'flex justify-between lg:justify-start'}>
            <div className={'flex items-center mb-4'}>
              <h4 className={'search-hits--headline mb-0'}>
                <FormattedMessage id={'search.searchHits.productHeadline'} />
              </h4>
              <div className={'search-hits-count ml-2 lg:mr-4'}>
                (
                <span>{numberOfHits}</span>
                )
              </div>
            </div>
            <div className={'search--link'}>
              <Link
                href={`/search/?query=${encodeURIComponent(value || '')}${filteredCategoryParams || ''}`}
                className={'search--link-all'}
                onClick={closeSearch}
              >
                <FormattedMessage id={'search.searchHits.linkAll'} />
              </Link>
            </div>
          </div>

          <div
            className={'search-product--list search-hits--list'}
            data-insights-index={indexName}
          >
            {hits.map((hit: AlgoliaHit) => (
              <Link
                href={hit.product_url}
                className={'search-product-hit search-insights-hit'}
                key={hit.objectID}
                onClick={() => {
                  if (hit.__queryID) {
                    window.localStorage.setItem('productQueryIds', hit.__queryID)
                  }
                  window.localStorage.setItem('indexNameAlgolia', indexName)
                  closeSearch()
                }}
                data-insights-object-id={hit.objectID}
                data-insights-position={hit.__position}
                data-insights-query-id={hit.__queryID}
              >
                {hit.image && hit.name
                  ? /* @ts-ignore */ (
                    <div className="w-[115px] flex">
                      <ProductImage
                        url={hit.image}
                        alt={hit.name}
                        title={hit.name}
                        width={115}
                        height={115}
                      />
                    </div>
                  )
                  : (
                    <div className={'search-hit--placeholder'} />
                  )}

                <div className={'search-product-hit-content'}>
                  {hit.brand && <div className={'search-hit--brand'}>{hit.brand}</div>}
                  <div className={'search-hit--name'}>
                    {hit.attributes.att_prod_product_name && (
                      <span className={'mr-1'}>{hit.attributes.att_prod_product_name}</span>
                    )}
                    {filterAttributeShoeWidth(hit) && (
                      <span className={'mr-1'}>
                        {filterAttributeShoeWidth(hit)}
                      </span>
                    )}
                    {hit.attributes.att_prod_gender && (
                      <span>{hit.attributes.att_prod_gender}</span>
                    )}
                  </div>
                  {hit.price_variants ? (
                    <div className={'search-hit--price'}>
                      {renderPrice(hit)}
                    </div>
                  ) : (
                    <div className={'search-hit--price'}>
                      <Price value={hit.price} />
                    </div>
                  )}
                </div>
              </Link>
            ))}
          </div>
        </div>
      )}
    </>
  )
}

export default injectIntl(SearchHits)
