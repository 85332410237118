import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Link from '@frontastic/catwalk/src/js/app/link'
import { FormattedHTMLMessage } from 'react-intl'
import SportLogo from '../SportLogo'

const TopCategories = ({
    category,
    currentTopCategory,
    handleSelectTopCategory,
    setCurrentOpenedMenu,
    setCurrentActiveItem,
}) => {
    const ref = useRef(null)

    return (
        <div
            className={classnames('header-mobile--navigation-bottom', {
                'is--sport': currentTopCategory === 1,
            })}
            ref={ref}
        >
            {category.map((item, index) => {
                const { length } = category
                let name
                switch (item.name) {
                    case 'Shop':
                        name = <SportLogo width={50} height={50} />
                        break
                    case 'Fussball':
                        name = <FormattedHTMLMessage id={'header.absoluteTeamsport'} />
                        break
                    case 'Running':
                        name = <FormattedHTMLMessage id={'header.runningExperts'} />
                        break
                    default:
                        name = item.name
                }

                if (item.tree && item.tree.children && !item.tree.children.length) {
                    return (
                        <Link
                            key={`${item.id}  + ${index}`}
                            path={item.reference?.target || '/'}
                            onClick={() => {
                                setCurrentOpenedMenu(null)
                                setCurrentActiveItem('')
                            }}
                            className={classnames('h-full tracking-normal whitespace-normal', {
                                active: index === currentTopCategory,
                            })}
                        >
                            {name}
                        </Link>
                    )
                }

                return (
                    <button
                        key={`${item.id}  + ${index}`}
                        aria-label={item.name}
                        type={'button'}
                        onClick={(event) => {
                            handleSelectTopCategory(event, index)
                        }}
                        className={classnames('h-full tracking-normal whitespace-normal', {
                            active: index === currentTopCategory,
                            'main-logo': length > 1 && index === 1,
                        })}
                    >
                        {name}
                    </button>
                )
            })}
        </div>
    )
}

TopCategories.propTypes = {
    category: PropTypes.arrayOf(PropTypes.any).isRequired,
    currentTopCategory: PropTypes.number.isRequired,
    handleSelectTopCategory: PropTypes.func,
    setCurrentActiveItem: PropTypes.func,
    setCurrentOpenedMenu: PropTypes.func,
}

export default TopCategories
